<template>
  <div>
    <!-- table -->
    <div v-if="!rows" class="text-center">
        <b-spinner label="Tunggu..."></b-spinner>
    </div>
    <vue-good-table
      v-if="rows"
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <span v-else-if="props.column.field === 'voucher_value_formatted'">
          <span class="text-nowrap">{{ formatPrice(props.row.voucher_value) }}</span>
        </span>

        <!-- Column: Sold Status -->
        <span
          v-if="props.column.field === 'sold_status_label'"
          class="text-nowrap"
        >
          <p>
            <b-badge :variant="statusVariant(props.row.sold_status == 1)">
                    {{ (props.row.sold_status == 1) ? 'Terjual' : 'Belum Terjual'  }}
                </b-badge>
          </p>
        </span>

        <!-- Column: Used Status -->
        <span
          v-if="props.column.field === 'used_status_label'"
          class="text-nowrap"
        >
        <p>
            <b-badge :variant="statusVariant(props.row.used_status == 1)">
                    {{ (props.row.used_status == 1) ? 'Dipakai' : 'Belum Dipakai'  }}
                </b-badge>
          </p>
        </span>


        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Data 1 hingga
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> dari {{ props.total }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BBadge
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner,
    BBadge
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatPrice(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    statusVariant(status) {
      return status ? 'light-success' : 'light-danger'
    },
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number',
        },
        {
          label: 'Kode',
          field: 'voucher_code',
        },
        {
          label: 'Nominal',
          field: 'voucher_value_formatted',
        },
        {
          label: 'Status Terjual',
          field: 'sold_status_label',
        },
        {
          label: 'Status Digunakan',
          field: 'used_status_label',
        },
      ],
    }
  },
}
</script>