<template>
  <div>
    <b-row>
      <b-col cols="12">
        <report-content />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ReportContent from './ReportContent.vue'

export default {
  components: {
    BRow,
    BCol,
    ReportContent
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>